<template>
  <section
    ref="popBarcode"
    id="popBarcode"
    class="pop barcode"
    @click="close('close')"
    style="display: flex"
  >
    <div class="popBody" style="animation-name: popup">
      <a class="close" @click="close('close')">
        <font-awesome-icon icon="fa-solid fa-xmark" size="lg" />
      </a>
      <span class="title"> {{ $t("Carrier barcode") }} </span>
      <div class="code">
        <barcode
          :value="cardNo"
          format="code128"
          width="2"
          height="60px"
          displayValue="true"
          :margin="3"
        >
        </barcode>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
// import { mapState } from 'vuex';
import VueBarcode from "vue-barcode";
// import { mapState } from "vuex";

export default {
  name: "PopupBarcode",
  props: {
    cardNo: String,
  },
  components: {
    barcode: VueBarcode,
  },
  // computed: {
  //   ...mapState({
  //     user: (state) => state.user,
  //   }),
  //   cardNo() {
  //     if (this.user && this.user.user && this.user.user.cardNo) {
  //       return this.user.user.cardNo.trim();
  //     }
  //     return null;
  //   },
  // },
  data() {
    return {};
  },
  mounted() {
    this.popup();
  },
  methods: {
    close(eventName) {
      const self = this;
      const pop = this.$refs.popBarcode; // document.getElementById('popError');
      pop.children[0].style.animationName = "popdown";
      this.$store.commit('updateShowUserCarrier', false)

      setTimeout(function () {
        document.querySelector("body").setAttribute("style", "overflow:auto");
        self.$emit("close", eventName);
        this.$store.commit('updateShowUserCarrier', false)
      }, 250);
    },
    popup() {
      const target = this.$refs.popBarcode;
      target.style.display = "flex";
      document.querySelector("body").setAttribute("style", "overflow:hidden");
    },
  },
};
</script>
